<template>
  <v-card
    id="user-form"
    min-height="350"
  >
    <!-- actions -->
    <v-fab-transition>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            color="secondary"
            dark
            absolute
            small
            top
            right
            fab
            class="mb-4 me-3"
            v-on="on"
            @click="goBack"
          >
            <v-icon>
              {{ icons.mdiTableArrowLeft }}
            </v-icon>
          </v-btn>
        </template>
        <span>{{ t("tooltip.back_table") }}</span>
      </v-tooltip>
    </v-fab-transition>

    <!-- tabs -->
    <v-tabs
      v-model="tab"
      show-arrows
      color="secondary"
    >
      <v-tab
        v-for="tab in tabs"
        :key="tab.icon"
        color="secondary"
        :disabled="tab.disabled"
      >
        <v-icon
          v-if="!tab.hide"
          size="20"
          class="me-3"
        >
          {{ tab.icon }}
        </v-icon>
        <span>{{ t(`fields.${tab.title}`) }}</span>
      </v-tab>
    </v-tabs>

    <!-- tabs item -->
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <field-tab-general
          :data-params="fieldData"
          :tab="tab"
          :option="option"
          :facilities="facilities"
          @changeTab="changeTab"
        ></field-tab-general>
      </v-tab-item>

      <v-tab-item>
        <field-tab-images
          :data-params="fieldData"
          :tab="tab"
          :option="option"
          @changeTab="changeTab"
          @cancelAction="cancelAction"
        ></field-tab-images>
      </v-tab-item>

      <v-tab-item>
        <field-tab-chunk-frecuency
          :data-params="fieldData"
          :tab="tab"
          :option="option"
          @save="save"
          @cancelAction="cancelAction"
        ></field-tab-chunk-frecuency>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
/* eslint-disable import/no-cycle */
import {
  mdiTableArrowLeft,
  mdiFormatListChecks,
  mdiClockOutline,
  mdiImageMultipleOutline,
} from '@mdi/js'
import { onMounted, ref, watch } from '@vue/composition-api'
import { useUtils } from '@core/libs/i18n'
import { useRouter } from '@core/utils'
import { error, success } from '@core/utils/toasted'
import {
  createField,
  editField,
  editFieldImage,
  getFieldsById,
} from '@api'
import useSelectOptions from '@/@core/utils/useSelectOptions'

// tabs
import FieldTabGeneral from './FieldTabGeneral.vue'
import FieldTabChunkFrecuency from './FieldTabChunkFrecuency.vue'
import FieldTabImages from './FieldTabImages.vue'

export default {
  components: {
    FieldTabGeneral,
    FieldTabChunkFrecuency,
    FieldTabImages,
  },
  setup() {
    const { t } = useUtils()
    const { route, router } = useRouter()
    const { configFacility, configOrganization } = useSelectOptions()

    const tab = ref(0)
    const option = ref(1)
    const fieldData = ref({})
    const pageData = ref()
    const filterData = ref()

    // hourly_discount: null,
    // pricing_type: null,
    const postData = ref({
      facility_id: null,
      sport_id: null,
      camera_id: null,
      name: null,
      hourly_pricing: null,
      status: 'A',
      schedule: [],
      recording_type: null,
      players_qty: null,
      user_hourly_price: null,
      booking_hourly_price: null,
      allow_public_cards: 'N',
      premium_video_available: 'Y',
      blocked_fields_ids: [],
    })

    // hourly_discount: null,
    // pricing_type: null,
    const putData = ref({
      facility_id: null,
      sport_id: null,
      camera_id: null,
      name: null,
      hourly_pricing: null,
      status: 'A',
      schedule: [],
      recording_type: null,
      players_qty: null,
      user_hourly_price: null,
      booking_hourly_price: null,
      allow_public_cards: 'N',
      premium_video_available: 'Y',
      blocked_fields_ids: [],
    })

    const dataId = ref(null)
    const imagesDescription = ref([])
    const facilities = ref([])

    // tabs
    const tabs = ref([
      {
        title: 'general',
        icon: mdiFormatListChecks,
        disabled: false,
        hide: false,
      },
      {
        title: 'images',
        icon: mdiImageMultipleOutline,
        disabled: true,
        hide: false,
      },
      {
        title: 'schedule',
        icon: mdiClockOutline,
        disabled: true,
        hide: false,
      },
    ])

    const goBack = () => {
      // name: 'views-field-list',
      router.replace({
        name: 'views-facility-settings-view',
        params: {
          pageParams: pageData.value,
          filterParams: filterData.value,
        },
      })
    }

    const changeTab = (tabNumber, validForm, data) => {
      switch (tabNumber) {
        case 1:
          if (validForm) {
            tab.value = tabNumber
            tabs.value[1].disabled = false
          }
          break
        case 2:
          if (validForm) {
            tab.value = tabNumber
            tabs.value[2].disabled = false
            imagesDescription.value = data.imagesDescription
          }
          break
        case 3:
          if (validForm) {
            tab.value = tabNumber
            tabs.value[3].disabled = false
          }
          break
        default:
          break
      }

      tab.value = tabNumber
    }

    const updateImage = async image => {
      const formData = new FormData()
      if (image && dataId.value) {
        formData.append('file', image)
        const responseLogo = await editFieldImage(formData, dataId.value)

        if (responseLogo.ok) {
          success(responseLogo.message)
        }
      }
    }

    const save = async data => {
      let resp = null

      // hourly_discount: data.hourly_discount,
      // pricing_type: data.pricing_type,
      if (option.value === 1) {
        postData.value = Object.assign(postData.value, {
          facility_id: configFacility.value,
          sport_id: data.sport_id,
          camera_id: data.camera_id,
          name: data.name,
          hourly_pricing: data.hourly_pricing,
          status: data.status,
          schedule: data.schedule,
          recording_type: data.recording_type,
          players_qty: data.players_qty,
          user_hourly_price: data.user_hourly_price,
          booking_hourly_price: data.booking_hourly_price,
          allow_public_cards: data.allow_public_cards,
          premium_video_available: data.premium_video_available,
          blocked_fields_ids: data.blocked_fields_ids,
        })

        resp = await createField(postData.value)

        if (resp.ok) {
          dataId.value = resp.data.id
          if (imagesDescription.value && imagesDescription.value.length) {
            imagesDescription.value.forEach(async e => {
              await updateImage(e.data)
            })
          }
        }
      }

      if (option.value === 3) {
        // hourly_discount: data.hourly_discount,
        // pricing_type: data.pricing_type,
        putData.value = Object.assign(putData.value, {
          facility_id: configFacility.value,
          sport_id: data.sport_id,
          camera_id: data.camera_id,
          name: data.name,
          hourly_pricing: data.hourly_pricing,
          status: data.status,
          schedule: data.schedule,
          recording_type: data.recording_type,
          players_qty: data.players_qty,
          user_hourly_price: data.user_hourly_price,
          booking_hourly_price: data.booking_hourly_price,
          allow_public_cards: data.allow_public_cards,
          premium_video_available: data.premium_video_available,
          blocked_fields_ids: data.blocked_fields_ids,
        })

        resp = await editField(putData.value, data.id)

        if (imagesDescription.value && imagesDescription.value.length) {
          imagesDescription.value.forEach(async e => {
            await updateImage(e.data)
          })
        }
      }

      if (!option.value) {
        goBack()
      }

      if (resp.ok) {
        success(resp.message)
        goBack()
      } else {
        error(resp.message.text)
      }
    }

    const cancelAction = () => {
      tab.value = 0
      tabs.value[1].disabled = true
      tabs.value[2].disabled = true
      fieldData.value = {}
    }

    watch([configOrganization, configFacility], () => {
      goBack()
    })

    onMounted(async () => {
      dataId.value = route.value.params.id
      option.value = route.value.params.option || 2
      pageData.value = route.value.params.pageParams
      filterData.value = route.value.params.filterParams

      if (dataId.value) {
        const resp = await getFieldsById(dataId.value)
        if (resp.ok) {
          fieldData.value = resp.data
        } else goBack()
      } else option.value = 1

      if (option.value !== 1) {
        tabs.value.forEach(e => {
          e.disabled = false
        })
      }
    })

    return {
      tab,
      tabs,
      option,
      fieldData,
      postData,
      putData,
      dataId,
      imagesDescription,
      facilities,

      changeTab,
      save,
      cancelAction,
      goBack,

      // i18n
      t,

      icons: {
        mdiTableArrowLeft,
        mdiFormatListChecks,
        mdiClockOutline,
        mdiImageMultipleOutline,
      },
    }
  },
}
</script>
